<template>
<div class="verify-fullpage">
  <div class="verify-header">
    Build trust and credibility
  </div>
  <router-link to="/verifysignup" class="apply-now">
    Apply now
  </router-link>
  <div class="included-panel">
    <div class="included-header">
        What's included?
    </div>
    <div class="included-container">  
      <div class="included">
        <div class="panel-header">Verification check mark</div>
        There are 1442 clubs on campus. Make your club stand out
        <div><i class="fas fa-check"></i></div>
      </div>
      <div class="included">
        <div class="panel-header">Proffesional email address</div>
        Get a personalized address at YourClubName@bruinclubs.com
        <div><i class="fas fa-envelope"></i></div>
      </div>
      <div class="included">
        <div class="panel-header">Custom club page</div>
        Add images, info, logos, and more to your club page
        <div><i class="fas fa-paint-brush"></i></div>
      </div>
      <div class="included">
        <div class="panel-header">Reputation</div>
        There are only 10 verified clubs at any time
        <div><i class="fas fa-trophy"></i></div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  inheritAttrs: false,
}
</script>

<style scoped>
.verify-fullpage{
  padding: 100px 0 30px 0;
}
.verify-header{
  font-size: 80px;
  margin: 0 10px 30px 10px;
  font-weight: 600;
  background: -webkit-linear-gradient(left, red , yellow);
  background: -o-linear-gradient(right, red, yellow);
  background: -moz-linear-gradient(right, red, yellow);
  background: linear-gradient(to right, rgb(255, 167, 69) -21.02%, rgb(254, 134, 159) 25.98%, rgb(239, 122, 200) 44.98%, rgb(160, 131, 237) 71.99%, rgb(67, 174, 255) 91.99%); 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media screen and (max-width: 400px) {
  .verify-header{
    font-size: 52px;
  }
}
.apply-now{
  background: var(--primary-color);
  color: white;
  cursor: pointer;
  border: none;
  padding: 10px 15px;
  text-decoration: none;
  border-radius: 30px;
  transition: .3s all;
}
.apply-now:hover{
  background: var(--primary-color-light);
}
.included-panel{
  max-width: 700px;
  margin: 0 auto;
  padding: 0 20px;
  margin-bottom: 60px;
}
.included-header{
  margin: 100px 0 20px;
  font-size: 20px;
  color: var(--light-gray);
}
.included{
  margin-bottom: 20px;
  padding: 20px;
  background: #f2f2f2;
  border-radius: 3px;
  font-size: 14px;
  color: gray;
}
.panel-header{
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 4px;
  color: black;
}
.fas{
  color: #4285f4;
  font-size: 70px;
  margin-top: 20px;
}
</style>